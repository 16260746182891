<template>
  <VueEditor v-bind="$attrs" v-on="$listeners" v-model="editor_content" :editorToolbar="editor_toolbar" @text-change="handleTextChange" />
</template>

<script>
import { VueEditor } from 'vue2-editor/dist/vue2-editor.core';

export default {
  name: 'RichTextEditor',
  inheritAttrs: false,

  components: { VueEditor },

  props: {
    toolbar: {
      type: String,
      validator(value) {
        return ['basic', 'regular', 'full'].indexOf(value) !== -1;
      },
      default: 'regular',
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor_content: this.value,
    };
  },

  computed: {
    editor_toolbar() {
      let toolbarArr = [];

      switch (this.toolbar) {
        case 'basic':
          toolbarArr = [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', 'underline'], // toggled buttons
          ];
          break;
        case 'regular':
          toolbarArr = [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            ['link', 'image', 'video'],
            ['clean'], // remove formatting button
          ];
          break;
        case 'full':
          toolbarArr = [
            [{ font: [] }],
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            [{ size: ['small', false, 'large', 'huge'] }], // font size
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ direction: 'rtl' }], // text direction
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ['link', 'image', 'video'],
            ['clean'], // remove formatting button
          ];
          break;
        default:
          break;
      }

      return toolbarArr;
    },
  },

  watch: {
    value: function valueChanged(newVal) {
      this.editor_content = newVal;
    },
  },

  methods: {
    handleTextChange() {
      this.$emit('input', this.editor_content);
    },
  },
};

</script>

<style lang="stylus">

@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.snow.css';

</style>
